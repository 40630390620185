import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/[campusSlug]/[deptId]": [7,[2,3]],
		"/[campusSlug]/[deptId]/inside": [8,[2,3,4]],
		"/[campusSlug]/[deptId]/inside/[photosphereId]": [9,[2,3,4]],
		"/[campusSlug]/[deptId]/navigation": [10,[2,3,5]],
		"/[campusSlug]/[deptId]/navigation/arrived": [11,[2,3,5]],
		"/[campusSlug]/[deptId]/navigation/driving": [12,[2,3,5]],
		"/[campusSlug]/[deptId]/navigation/parking": [13,[2,3,5]],
		"/[campusSlug]/[deptId]/navigation/routeDisplay": [14,[2,3,5]],
		"/[campusSlug]/[deptId]/navigation/walking": [15,[2,3,5]],
		"/[campusSlug]/[deptId]/survey": [16,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';